<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-card title="ÖSYM Soruları" style="min-height: 240px; width: 100%">
        <div
          class="d-flex justify-content-center align-items-center"
          style="width: 100%; margin-top: 80px"
        >
          <b-spinner label="Loading..." />
        </div>
      </b-card>
    </div>

    <div v-else>
      <b-card style="min-height: 240px">
        <h4>
          ÖSYM Soruları
          <span style="font-size: 0.9em; margin-left: 6px; font-weight: 400">{{
            "(" + totalQuestionCount + " soru)"
          }}</span>
        </h4>
        <b-row class="mt-2">
          <b-col :md="selectedExam ? 7 : 12" class="">
            <b-table
              ref="examTable"
              responsive
              selectable
              hover
              :per-page="examsPerPage"
              :current-page="examsCurrentPage"
              select-mode="single"
              :items="osymExams"
              :fields="examsTableFields"
              @row-selected="onExamSelected"
            >
              <template #cell(year_tus_number)="data">
                {{ data.item.year }} - {{ data.item.tus_number }}
              </template>

              <template #cell(question_count)="data">
                <p style="margin-left: 15px; margin-bottom: 0px">
                  {{ data.item.total_question_count }}
                </p>
              </template>

              <template #cell(distribution)="data">
                Temel: {{ data.item.basic_question_count }}, Klinik:
                {{ data.item.clinical_question_count }}
              </template>

              <template #cell(incomplete_question_count)="data">
                <p style="margin-left: 25px; margin-bottom: 0px">
                  {{ data.item.incomplete_question_count }} Soru
                </p>
              </template>
            </b-table>

            <div class="mt-1">
              <b-pagination
                v-model="examsCurrentPage"
                :total-rows="examsTotalRows"
                :per-page="examsPerPage"
                :align="'center'"
                style="margin-bottom: 0px"
              />
            </div>
          </b-col>
          <b-col v-if="selectedExam" md="5" class="px-2">
            <h4 class="text-warning">
              {{ selectedExam.year }} - {{ selectedExam.tus_number }} Soru
              Dağılımı
            </h4>
            <hr style="margin: 5px 0px 15px 0px" />

            <b-row class="">
              <b-col md="6" class="">
                <p
                  v-for="(course, index) in getQuestionDistribution(
                    'Temel Bilimler',
                    true
                  )"
                  :key="`${course.title} ${index}`"
                  :class="
                    index == 0
                      ? 'font-weight-bold text-primary'
                      : 'font-weight-bold'
                  "
                >
                  {{ course.title }}: {{ course.count }}
                </p>
              </b-col>
              <b-col md="6" class="">
                <p
                  v-for="(course, index) in getQuestionDistribution(
                    'Klinik Bilimler',
                    false
                  )"
                  :key="`${course.title} ${index}`"
                  :class="
                    index == 0
                      ? 'font-weight-bold text-primary'
                      : 'font-weight-bold'
                  "
                >
                  {{ course.title }}: {{ course.count }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="selectedExam" style="min-height: 240px">
        <div class="d-flex justify-content-between align-items-center">
          <h4 style="min-width: 280px; margin-top: 5px">
            {{
              selectedExam.year +
                " - " +
                selectedExam.tus_number +
                " / Sorular (" +
                questionsOfSelectedExam.length +
                ")"
            }}
          </h4>
          <b-form-input
            v-model="questionListFilter"
            placeholder="Soru Ara"
            autocomplete="off"
            class="ml-2"
            style="max-width: 400px"
          />
        </div>

        <b-table
          ref="examQuestionsTable"
          responsive
          selectable
          hover
          select-mode="single"
          class="mt-1"
          :per-page="questionsPerPage"
          :current-page="questionsCurrentPage"
          :items="questionsInOrderOfSelectedExam"
          :fields="examQuestionsTableFields"
          @row-selected="onExamQuestionSelected"
        >
          <template #cell(question_order)="data">
            <div style="display: flex; align-items: center">
              <div style="margin-right: 5px">
                {{ data.item.question_order }}
              </div>
              <b-badge v-if="data.item.canceled" variant="warning">
                İptal
              </b-badge>
            </div>
          </template>
          <template #cell(course_name)="data">
            {{ toTitleCase(data.value) }}
          </template>
          <template #cell(correct_answer)="data">
            <p style="margin: 0px 0px 0px 6px">
              {{ data.item.answer }}
            </p>
          </template>

          <template #cell(subject_name)="data">
            {{ toTitleCase(data.value) }}
          </template>
          <template #cell(question_text)="data">
            <p
              class="max-one-line"
              style="margin-bottom: 0px; max-width: 350px"
            >
              {{ data.value }}
            </p>
          </template>
          <template #cell(solution)="data">
            <p
              class="max-one-line"
              style="margin-bottom: 0px; max-width: 500px"
            >
              {{ data.value }}
            </p>
          </template>
          <template #cell(condition)="data">
            <p
              v-if="isQuestionCompleted(data.item)"
              style="margin-bottom: 0px"
              class="text-success"
            >
              Tamamlandı
            </p>
            <p v-else style="margin-bottom: 0px" class="text-warning">
              Düzenlenecek
            </p>
          </template>
        </b-table>

        <div class="mt-1">
          <b-pagination
            v-model="questionsCurrentPage"
            :total-rows="questionssTotalRows"
            :per-page="questionsPerPage"
            :align="'center'"
            style="margin-bottom: 0px"
          />
        </div>
      </b-card>

      <b-card style="min-height: 240px; margin-bottom: 60px">
        <div style="display: flex">
          <h4 class="flex-grow-1">
            {{
              selectedQuestion
                ? selectedQuestion.year +
                  " - " +
                  selectedQuestion.tus_number +
                  " / Soru: " +
                  selectedQuestion.question_order +
                  " "
                : "Yeni Soru Ekle"
            }}
            <span
              v-if="selectedQuestion"
              class="text-primary"
              style="font-size: 0.8em; font-weight: normal"
              >(#{{ selectedQuestion.oq_id }})</span
            >

            <b-button-group
              v-if="
                selectedQuestion && selectedQuestion.alternatives.length > 0
              "
              size="sm"
              style="margin-left: 10px"
            >
              <b-button
                @click="fillQuestionData(selectedQuestion, -1)"
                :variant="
                  selectedAlternativeIndex === -1
                    ? 'primary'
                    : 'outline-warning'
                "
              >
                AI
              </b-button>

              <b-button
                v-for="(alt, index) in selectedQuestion.alternatives"
                :key="`${alt.oq_id} ${index}`"
                @click="fillQuestionData(alt, index)"
                :variant="
                  selectedAlternativeIndex === index
                    ? 'primary'
                    : 'outline-info'
                "
              >
                Alternatif {{ index + 1 }}
              </b-button>
            </b-button-group>
          </h4>

          <b-button
            v-if="selectedQuestion"
            variant="outline-danger"
            @click="showAddQuestion()"
            size="sm"
            style="float: right; margin-bottom: 10px"
          >
            Güncellemeden Çık
          </b-button>
        </div>

        <hr style="margin: 3px 0px 25px 0px" />

        <div>
          <b-row>
            <b-col xl="6" cols="12">
              <b-row>
                <b-col md="2" cols="12">
                  <label for="question-order">Soru Sırası</label>
                  <b-form-input
                    id="question-order"
                    v-model="questionOrder"
                    placeholder="82"
                    autocomplete="off"
                    :formatter="questionOrderFormatter"
                    style="margin-right: 5px"
                  />
                </b-col>
                <b-col md="2" cols="12">
                  <label for="year-tus-number">Yıl - Sınav:</label>
                  <b-form-group id="year-tus-number">
                    <b-form-input
                      v-model="yearTusNumber"
                      placeholder="2024-2"
                      autocomplete="off"
                      :formatter="yearTusNumberFormatter"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="8" cols="12">
                  <label for="course-subject">Ders - Konu:</label>
                  <b-form-group id="course-subject">
                    <v-select
                      v-model="selectedCourseSubjectText"
                      :options="courseSubjectOptions"
                      :filterBy="filterBy"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-textarea
                id="question-text"
                placeholder="Soru metnini yazın"
                rows="3"
                max-rows="6"
                class="mb-1 mb-xl-0"
                v-model="questionText"
              />
              <div style="display: flex; margin-top: 5px; margin-bottom: 25px">
                <b-button
                  v-for="(tagName, index) in questionTagList"
                  :key="index"
                  class="tag-button"
                  variant="outline-primary"
                  size="sm"
                  style="min-width: 40px; margin-right: 10px"
                  @click="questionText = applyTag(tagName, questionText)"
                  v-b-tooltip.hover.v-primary
                  :title="getTagTooltipText(tagName)"
                >
                  {{ tagName }}
                </b-button>
              </div>

              <div class="mt-1" style="display: flex">
                <div class="mr-2">
                  <b-button
                    :variant="answer == 'A' ? 'primary' : 'outline-secondary'"
                    @click="answer = 'A'"
                    class="btn-icon rounded-circle"
                  >
                    A
                  </b-button>
                </div>
                <div style="width: 100%">
                  <b-form-input
                    v-model="optionA"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="mt-1" style="display: flex">
                <div class="mr-2">
                  <b-button
                    :variant="answer == 'B' ? 'primary' : 'outline-secondary'"
                    @click="answer = 'B'"
                    class="btn-icon rounded-circle"
                  >
                    B
                  </b-button>
                </div>
                <div style="width: 100%">
                  <b-form-input
                    v-model="optionB"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="mt-1" style="display: flex">
                <div class="mr-2">
                  <b-button
                    :variant="answer == 'C' ? 'primary' : 'outline-secondary'"
                    @click="answer = 'C'"
                    class="btn-icon rounded-circle"
                  >
                    C
                  </b-button>
                </div>
                <div style="width: 100%">
                  <b-form-input
                    v-model="optionC"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="mt-1" style="display: flex">
                <div class="mr-2">
                  <b-button
                    :variant="answer == 'D' ? 'primary' : 'outline-secondary'"
                    @click="answer = 'D'"
                    class="btn-icon rounded-circle"
                  >
                    D
                  </b-button>
                </div>
                <div style="width: 100%">
                  <b-form-input
                    v-model="optionD"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="mt-1" style="display: flex">
                <div class="mr-2">
                  <b-button
                    :variant="answer == 'E' ? 'primary' : 'outline-secondary'"
                    @click="answer = 'E'"
                    class="btn-icon rounded-circle"
                  >
                    E
                  </b-button>
                </div>
                <div style="width: 100%">
                  <b-form-input
                    v-model="optionE"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
              </div>

              <label class="mt-2" for="detailed-solution">Açıklama:</label>
              <b-form-textarea
                id="detailed-solution"
                placeholder="Açıklama metni"
                rows="6"
                max-rows="12"
                class="mb-1 mb-xl-0"
                v-model="solution"
              />
              <div style="display: flex; margin-top: 5px; margin-bottom: 15px">
                <b-button
                  v-for="(tagName, index) in solutionTagList"
                  :key="index"
                  class="tag-button"
                  variant="outline-primary"
                  size="sm"
                  style="min-width: 40px; margin-right: 10px"
                  @click="solution = applyTag(tagName, solution)"
                  v-b-tooltip.hover.v-primary
                  :title="getTagTooltipText(tagName)"
                >
                  {{ tagName }}
                </b-button>
              </div>

              <label class="mt-2" for="tags">Etiketler</label>
              <b-form-textarea
                id="tags"
                placeholder="Etiketler (tag1 & tag2)"
                rows="2"
                max-rows="3"
                class="mb-1 mb-xl-0"
                v-model="tags"
              />

              <b-row v-if="uploadedImageUrls" class="match-height mt-2">
                <b-col
                  v-for="imageUrl in uploadedImageUrls"
                  :key="imageUrl"
                  lg="6"
                >
                  <b-img
                    :src="imageUrl"
                    fluid
                    alt="..."
                    style="height: 240px; object-fit: contain"
                  />
                  <b-form-input
                    readonly
                    :value="'[[' + imageUrl + ']]'"
                    class="mt-1"
                  />
                </b-col>
              </b-row>

              <b-form-file
                id="file-select"
                class="mt-2"
                browse-text="Fotograf Seç"
                v-model="newImage"
                placeholder="Bir fotograf seçin veya buraya sürükleyin..."
                accept="image/jpeg, image/jpg, image/png"
              />

              <div v-if="newImage" class="mt-1">
                <b-card-text class="my-1" style="float: left">
                  Seçili fotograf:
                  <strong>{{ newImage ? newImage.name : "" }}</strong>
                </b-card-text>
                <b-button
                  variant="outline-primary"
                  @click="uploadNewImage()"
                  size="sm"
                  class="mt-1"
                  style="float: right"
                >
                  Yükle
                </b-button>
              </div>
            </b-col>

            <b-col xl="6" cols="12">
              <b-card title="Soru önizleme">
                <b-card-text style="white-space: pre-wrap">
                  <div
                    v-if="questionText"
                    v-html="
                      '<strong>Soru:</strong> ' + getHtmlWithImage(questionText)
                    "
                  ></div>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap">
                  <p
                    :class="
                      answer == 'A' ? 'font-weight-bolder text-primary' : ''
                    "
                  >
                    <strong>A:</strong> {{ optionA }}
                  </p>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap">
                  <p
                    :class="
                      answer == 'B' ? 'font-weight-bolder text-primary' : ''
                    "
                  >
                    <strong>B:</strong> {{ optionB }}
                  </p>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap">
                  <p
                    :class="
                      answer == 'C' ? 'font-weight-bolder text-primary' : ''
                    "
                  >
                    <strong>C:</strong> {{ optionC }}
                  </p>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap">
                  <p
                    :class="
                      answer == 'D' ? 'font-weight-bolder text-primary' : ''
                    "
                  >
                    <strong>D:</strong> {{ optionD }}
                  </p>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap">
                  <p
                    :class="
                      answer == 'E' ? 'font-weight-bolder text-primary' : ''
                    "
                  >
                    <strong>E:</strong> {{ optionE }}
                  </p>
                </b-card-text>
                <b-card-text style="white-space: pre-wrap" class="mt-2">
                  <p>
                    <strong>Alan: </strong>
                    {{ toTitleCase(selectedCourseSubjectText) }}
                  </p>
                </b-card-text>

                <b-card-text class="mt-3" style="white-space: pre-wrap">
                  <div style="margin-top: 40px">
                    <h3>Açıklama</h3>
                    <hr />
                    <div
                      v-if="solution && isJson(solution.replaceAll('\n', ''))"
                    >
                      <div
                        v-for="(value, key) in JSON.parse(
                          solution.replaceAll('\n', '')
                        )"
                        :key="key"
                      >
                        <h5
                          class="text-primary"
                          style="margin: 15px 0px 5px 0px;"
                        >
                          {{ key }}
                        </h5>
                        <div
                          v-html="
                            selectedQuestion && selectedAlternativeIndex != -1
                              ? highlightedDiff(
                                  JSON.parse(selectedQuestion.solution)[key],
                                  value
                                )
                              : value
                          "
                          style="margin-left: 15px;"
                        ></div>
                      </div>
                    </div>
                    <div v-else>
                      <p>{{ solution }}</p>
                    </div>
                  </div>
                </b-card-text>

                <div
                  v-if="selectedQuestion && selectedAlternativeIndex == -1"
                  class="mt-3"
                >
                  <div style="display: flex; justify-content: flex-end">
                    <b-button
                      variant="primary"
                      @click="updateSelectedQuestion()"
                      class="mx-1"
                    >
                      Soruyu Güncelle
                    </b-button>
                  </div>

                  <div>
                    <hr class="mt-2" />
                    <b-alert
                      v-if="selectedQuestion.canceled"
                      variant="warning"
                      show
                      class="mt-2"
                    >
                      <h4 class="alert-heading">İptal Açıklaması</h4>
                      <div class="alert-body">
                        {{ selectedQuestion.cancel_description }}
                      </div>
                    </b-alert>

                    <div
                      class="mt-1"
                      style="display: flex; justify-content: flex-end"
                    >
                      <b-button
                        v-if="selectedQuestion.canceled"
                        variant="outline-primary"
                        @click="showRemoveQuestionCancelModal()"
                        class="mx-1"
                      >
                        İptali Kaldır
                      </b-button>
                      <b-button
                        v-else
                        variant="warning"
                        @click="showCancelQuestionModal()"
                        class="mx-1"
                      >
                        İptal Et
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        v-b-modal.modal-delete-question
                        class="mx-1"
                      >
                        Soruyu Sil
                      </b-button>
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="mt-3"
                  style="display: flex; justify-content: flex-end"
                >
                  <b-button
                    v-if="selectedAlternativeIndex === -1"
                    variant="warning"
                    @click="addQuestion()"
                    class="mx-1"
                    v-b-tooltip.hover.v-primary
                    title="CTRL/CDM + E"
                  >
                    Soruyu Ekle
                  </b-button>

                  <b-button
                    v-if="selectedQuestion && selectedQuestion.allowApply"
                    variant="success"
                    @click="updateSelectedQuestion(true)"
                    class="mx-1"
                    v-b-tooltip.hover.v-primary
                    title="Alternatif Seçildi - Değişiklikleri Uygula"
                  >
                    Değişiklikleri Uygula
                  </b-button>
                </div>

                <b-modal
                  id="modal-delete-question"
                  ref="modal-delete-question"
                  hide-footer
                  centered
                  title="Soruyu Sil"
                >
                  <p class="m-1">
                    Seçili soru tamamen silinecek. Bu işlem geri alınamaz!
                  </p>

                  <hr style="margin: 20px 10px" />
                  <div>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="primary"
                      @click="$refs['modal-delete-question'].hide()"
                    >
                      Kapat
                    </b-button>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="danger"
                      @click="deleteSelectedQuestion()"
                    >
                      Sil
                    </b-button>
                  </div>
                </b-modal>

                <b-modal
                  ref="modal-cancel-question"
                  hide-footer
                  centered
                  title="Soruyu İptal Et"
                >
                  <p>Lütfen sorunun iptal edilme sebebini açıklayın.</p>
                  <label class="mt-1" for="solution-text"
                    >İptal sebibi (maks 2-3 satır)</label
                  >
                  <b-form-textarea
                    id="solution-text"
                    placeholder="Zorunlu alan"
                    rows="2"
                    max-rows="8"
                    class="mb-1 mb-xl-0"
                    v-model="cancelDescription"
                  />
                  <hr style="margin: 20px 10px" />
                  <div>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="primary"
                      @click="$refs['modal-cancel-question'].hide()"
                    >
                      Kapat
                    </b-button>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="warning"
                      @click="cancelQuestion()"
                    >
                      İptal Et
                    </b-button>
                  </div>
                </b-modal>

                <b-modal
                  ref="modal-remove-cancel"
                  hide-footer
                  centered
                  title="Soru İptalini Kaldır"
                >
                  <p class="m-1">
                    Soru iptali kaldırılacak ve kullanıcıların puanları
                    verdikleri cevaplar üzerinden tekrar hesaplanacak.
                  </p>

                  <hr style="margin: 20px 10px" />
                  <div>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="primary"
                      @click="$refs['modal-remove-cancel'].hide()"
                    >
                      Kapat
                    </b-button>
                    <b-button
                      class="mr-1"
                      style="float: right"
                      variant="danger"
                      @click="removeQuestionCancel()"
                    >
                      Kaldır
                    </b-button>
                  </div>
                </b-modal>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-alert variant="warning" show class="mt-1">
      <h4 class="alert-heading">Kullanım Önerileri</h4>
      <div class="alert-body">
        <ul style="line-height: 2; margin: 5px 0px 0px -10px">
          <li>
            Soru ve seçenekler toplu bir şekilde "Soru Metni" alanına
            yapıştırırsanız sorunun tüm bölümleri otomatik olarak kendi alanına
            geçer.
          </li>
          <li>
            Tüm seçenekleri A seçeneği alanına yapıştırırsanız yapıştırılan
            metin tüm seçeneklere dağılır.
          </li>
          <li>
            Soru bilgilerini girdikten sonra Ctrl/CMD+S yaparsanız soru eklenir.
          </li>
          <li>
            Bir soru seçiliyken Ctrl/CMD+S tuşlarsanız yaptığınız değişiklikler
            kaydedilir.
          </li>
          <li>
            Shift + sağ/sol tuşları ile "Sorular" tablosunda hızlı bir şekilde
            gezilebilir.
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BTable,
  BSpinner,
  BPagination,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BAlert,
  BBadge,
  BButtonGroup,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DiffMatchPatch from "diff-match-patch";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    flatPickr,
    BButton,
    BFormFile,
    BFormTextarea,
    vSelect,
    BTabs,
    BTab,
    BImg,
    BAlert,
    BBadge,
    BButtonGroup,
    VBTooltip,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      selectedAlternativeIndex: -1,
      loading: true,
      keydownHandler: null,
      examsPerPage: 5,
      examsCurrentPage: 1,
      osymExams: [],
      selectedExam: null,
      questionFilter: "",
      selectedCourseName: null,
      questionListFilter: "",
      lastAddUpdateQuestionCall: null,
      questionsCurrentPage: 1,
      questionsPerPage: 5,
      questionsOfSelectedExam: [],
      examQuestionsTableFields: [
        { key: "question_order", label: "Sıra" },
        { key: "course_name", label: "Ders" },
        { key: "subject_name", label: "Konu" },
        { key: "question_text", label: "Soru" },
        { key: "correct_answer", label: "Cevap" },
        { key: "solution", label: "Açıklama" },
        { key: "condition", label: "Durumu" },
      ],
      examsTableFields: [
        { key: "year_tus_number", label: "Sınav" },
        { key: "question_count", label: "Soru Sayısı" },
        { key: "distribution", label: "Soru Dağılımı" },
        { key: "incomplete_question_count", label: "Düzenlenecek" },
      ],
      selectedQuestion: null,
      courseSubjects: [],
      selectedCourseSubjectText: null,
      questionOrder: 1,
      yearTusNumber: "",
      questionText: "",
      lastQuestionTextLength: 0,
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      solution: "",
      tags: "",
      newImage: null,
      uploadedImageUrls: [],
      cancelDescription: "",
      inputSelectionStart: 0,
      inputSelectionEnd: 0,
      questionTagList: ["b", "u", "i", "ol", "ul", "br", "a"],
      solutionTagList: ["b", "u", "i", "ol", "ul", "h3", "h4", "br", "a"],
    };
  },

  computed: {
    totalQuestionCount() {
      return this.osymExams.reduce((total, exam) => {
        return total + (exam.total_question_count || 0);
      }, 0);
    },

    courseNameList() {
      var courseNameList = [];
      this.courseSubjects.forEach(function(courseSubject) {
        if (!courseNameList.includes(courseSubject.course_name)) {
          courseNameList.push(courseSubject.course_name);
        }
      });
      return courseNameList;
    },

    questionsInOrderOfSelectedExam() {
      var cloneList = this.questionsOfSelectedExam.slice();
      var orderCounter = 1;
      cloneList.forEach(function(question) {
        question["order"] = orderCounter++;
      });
      if (!this.questionListFilter) {
        return cloneList;
      }
      var filteredList = [];
      var filter = this.questionListFilter.toLocaleLowerCase("tr-TR");
      cloneList.forEach((question) => {
        if (
          (
            question.course_name +
            question.subject_name +
            question.question_text +
            question.option_a +
            question.option_b +
            question.option_c +
            question.option_d +
            question.option_e +
            question.solution +
            question.question_order +
            question.oq_id
          )
            .toLocaleLowerCase("tr-TR")
            .includes(filter)
        ) {
          filteredList.push(question);
        }
      });
      return filteredList;
    },

    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
    examsTotalRows() {
      return this.osymExams.length;
    },
    questionssTotalRows() {
      return this.questionsOfSelectedExam.length;
    },

    courseSubjectOptions() {
      var options = [];
      var courseNameList = [];
      this.courseSubjects.forEach(function(courseSubject) {
        if (!courseNameList.includes(courseSubject.course_name)) {
          courseNameList.push(courseSubject.course_name);
          options.push(courseSubject.course_name);
        }
        if (courseSubject.subject_name) {
          options.push(
            courseSubject.course_name + " -> " + courseSubject.subject_name
          );
        }
      });
      options.sort((a, b) => a.localeCompare(b, "tr"));
      return options;
    },
  },

  watch: {
    questionListFilter: function(newValue) {
      if (newValue) {
        this.questionsCurrentPage = 1;
      }
    },

    questionText: function(newValue) {
      if (newValue) {
        const parts = newValue.split(/(?=\s[A-E]\)\s)/);
        if (parts.length === 6) {
          const optionMap = {
            A: this.optionA,
            B: this.optionB,
            C: this.optionC,
            D: this.optionD,
            E: this.optionE,
          };

          parts.slice(1).forEach((part) => {
            const letter = part.charAt(1);
            const content = part.substring(3).trim();
            if (letter in optionMap) {
              optionMap[letter] = content;
            }
          });

          this.optionA = optionMap.A;
          this.optionB = optionMap.B;
          this.optionC = optionMap.C;
          this.optionD = optionMap.D;
          this.optionE = optionMap.E;

          this.questionText = parts[0].trim();
        } else {
          // Check if the change is likely due to a paste operation
          const changeSize = Math.abs(
            newValue.length - this.lastQuestionTextLength
          );
          if (this.selectedQuestion == null && changeSize > 50) {
            // Remove new lines from questionText
            this.questionText = newValue.replace(/\n/g, " ").trim();
            // Remove double spaces from questionText
            this.questionText = this.questionText.replace(/\s+/g, " ");

            const replacements = [
              { from: "IV.", to: "\nIV." },
              { from: "III.", to: "\nIII." },
              { from: "II.", to: "\nII." },
            ];

            replacements.forEach(({ from, to }) => {
              if (!this.questionText.includes(to)) {
                this.questionText = this.questionText.replace(from, to);
              }
            });
          }
        }
      }
      // Update the last known length
      this.lastQuestionTextLength = this.questionText.length;
    },

    optionA: function(newValue) {
      if (newValue) {
        const options = newValue
          .split(/(?=\s[A-E]\)\s)/)
          .map((opt) => opt.trim());
        if (options.length === 5) {
          const optionMap = {
            A: this.optionA,
            B: this.optionB,
            C: this.optionC,
            D: this.optionD,
            E: this.optionE,
          };

          options.forEach((option) => {
            const letter = option.charAt(0);
            const content = option.substring(2).trim();
            if (letter in optionMap) {
              optionMap[letter] = content;
            }
          });

          this.optionA = optionMap.A;
          this.optionB = optionMap.B;
          this.optionC = optionMap.C;
          this.optionD = optionMap.D;
          this.optionE = optionMap.E;
        }
      }
    },
  },

  methods: {
    highlightedDiff(oldText, newText) {
      const dmp = new DiffMatchPatch();
      const diff = dmp.diff_main(oldText, newText);
      dmp.diff_cleanupSemantic(diff);

      return diff
        .map((part) => {
          const [operation, text] = part;
          if (operation === 1) {
            // Insertion
            return `<span style="background-color: lightgreen;">${text}</span>`;
          } else if (operation === -1) {
            // Deletion
            return `<span style="background-color: lightcoral; text-decoration: line-through;">${text}</span>`;
          } else {
            // No change
            return text;
          }
        })
        .join("");
    },

    fillQuestionData(alternative, index) {
      this.selectedAlternativeIndex = index;
      this.questionOrder = alternative.question_order;
      this.yearTusNumber = alternative.year + "-" + alternative.tus_number;
      this.questionText = alternative.question_text;
      this.optionA = alternative.option_a;
      this.optionB = alternative.option_b;
      this.optionC = alternative.option_c;
      this.optionD = alternative.option_d;
      this.optionE = alternative.option_e;
      this.answer = alternative.answer;
      this.solution = alternative.solution;
      this.tags = alternative.tags;

      let courseName = alternative.course_name;
      let subjectName = alternative.subject_name;
      this.selectedCourseSubjectText = subjectName
        ? `${courseName} -> ${subjectName}`
        : courseName;
    },

    isJson(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },

    isBasicCourse(courseName) {
      return (
        courseName &&
        [
          "ANATOMİ",
          "BİYOKİMYA",
          "FARMAKOLOJİ",
          "FİZYOLOJİ",
          "HİSTOLOJİ VE EMBRİYOLOJİ",
          "MİKROBİYOLOJİ",
          "PATOLOJİ",
        ].includes(courseName.toLocaleUpperCase("tr-TR"))
      );
    },

    getQuestionDistribution(title, isBasic) {
      var courseList = [{ title: title, count: 0 }];
      for (var question of this.questionsOfSelectedExam) {
        var courseName = question.course_name;
        if (this.isBasicCourse(courseName) == isBasic) {
          courseList[0].count++;
          var courseObject = null;
          courseList.forEach(function(courseObj) {
            if (courseObj.title == courseName) {
              courseObject = courseObj;
            }
          });
          if (courseObject) {
            courseObject.count++;
          } else {
            courseObject = { title: courseName, count: 1 };
            courseList.push(courseObject);
          }
        }
      }
      for (var i = 1; i < courseList.length; i++) {
        courseList[i].title = this.toTitleCase(courseList[i].title);
      }
      return courseList;
    },

    showCancelQuestionModal() {
      this.$refs["modal-cancel-question"].show();
    },

    async cancelQuestion() {
      this.$refs["modal-cancel-question"].hide();
      if (!this.cancelDescription || this.cancelDescription.length < 10) {
        this.showError("Lütfen açıklama girin!");
        return;
      }
      var payload = {
        questionId: this.selectedQuestion.oq_id,
        cancelDescription: this.cancelDescription,
      };
      var response = await store.dispatch(
        "adminOsymQuestions/cancelQuestion",
        payload
      );
      if (response) {
        this.selectedQuestion.canceled = true;
        this.selectedQuestion.cancel_description = this.cancelDescription;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptal edildi.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    showRemoveQuestionCancelModal() {
      this.$refs["modal-remove-cancel"].show();
    },

    async removeQuestionCancel() {
      this.$refs["modal-remove-cancel"].hide();
      var response = await store.dispatch(
        "adminOsymQuestions/removeQuestionCancel",
        this.selectedQuestion.oq_id
      );
      if (response) {
        this.selectedQuestion.canceled = false;
        this.selectedQuestion.cancel_description = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptali kaldırıldı.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    getOptionAnswerCount(option) {
      var count = 0;
      this.questionsOfSelectedExam.forEach(function(question) {
        if (question.answer == option) {
          count++;
        }
      });
      return count;
    },

    onExamQuestionSelected(questions) {
      this.selectedAlternativeIndex = -1;
      var currentQuestion = questions[0];
      if (currentQuestion || this.selectedQuestion) {
        this.onQuestionSelected(currentQuestion);
      }
    },

    onQuestionSelected(question) {
      this.selectedQuestion = question;
      if (this.selectedQuestion) {
        this.questionOrder = this.selectedQuestion.question_order;
        this.yearTusNumber =
          this.selectedQuestion.year + "-" + this.selectedQuestion.tus_number;
        this.questionText = this.selectedQuestion.question_text;
        this.optionA = this.selectedQuestion.option_a;
        this.optionB = this.selectedQuestion.option_b;
        this.optionC = this.selectedQuestion.option_c;
        this.optionD = this.selectedQuestion.option_d;
        this.optionE = this.selectedQuestion.option_e;
        this.answer = this.selectedQuestion.answer;
        if (this.selectedQuestion.solution != null) {
          var solution = this.selectedQuestion.solution;
          solution = solution.replace(/<br>/g, "\n<br>");
          solution = solution.replace(/<ul>/g, "\n<ul>");
          solution = solution.replace(/<\/ul>/g, "\n</ul>");
          solution = solution.replace(/<li>/g, "\n    <li>");

          this.solution = solution;
        }

        this.tags = this.selectedQuestion.tags;
        this.uploadedImageUrls = [];

        var courseName = this.selectedQuestion.course_name;
        var subjectName = this.selectedQuestion.subject_name;

        this.selectedCourseSubjectText =
          subjectName && subjectName.length > 2
            ? courseName + " -> " + subjectName
            : courseName;
      } else {
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.tags = "";
        this.uploadedImageUrls = [];
      }
    },

    showAddQuestion() {
      this.selectedQuestion = null;
      this.questionText = "";
      this.optionA = "";
      this.optionB = "";
      this.optionC = "";
      this.optionD = "";
      this.optionE = "";
      this.answer = "";
      this.solution = "";
      this.tags = "";
      this.uploadedImageUrls = [];

      this.$nextTick(() => {
        if (this.$refs.examQuestionsTable) {
          this.$refs.examQuestionsTable.clearSelected();
        }
      });
    },

    onExamSelected(exams) {
      if (!exams) {
        this.selectedExam = null;
        return;
      }
      this.selectedQuestion = null;

      var exam = exams[0];

      this.selectedExam = exam;

      if (exam) {
        this.questionsOfSelectedExam = [];
        const payload = {
          year: exam.year,
          tusNumber: exam.tus_number,
          fetchAlternatives: true,
        };
        store
          .dispatch("adminOsymQuestions/getQuestionsOfExam", payload)
          .then((response) => {
            if (response) {
              this.questionsOfSelectedExam = response;
            }
          });
      }
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },

    showError(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: errorMessage,
          variant: "danger",
        },
      });
    },

    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "İşlem Başarılı!",
          icon: "BellIcon",
          text: message,
          variant: "success",
        },
      });
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .toLocaleLowerCase("tr-TR")
            .replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function(
              match
            ) {
              return match.toLocaleUpperCase("tr-TR");
            });
    },

    filterBy(option, label, search) {
      return (
        (label || "")
          .toLocaleLowerCase("tr-TR")
          .indexOf(search.toLocaleLowerCase("tr-TR")) > -1
      );
    },

    getHtmlWithImage(text) {
      if (!text) {
        return "";
      }
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      if (typeof text === "string") {
        return text
          .replaceAll("[[", "<img src='")
          .replaceAll(
            "]]",
            "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 640px;'/>"
          );
      } else {
        return JSON.parse(text)
          .replaceAll("[[", "<img src='")
          .replaceAll(
            "]]",
            "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 640px;'/>"
          );
      }
    },

    async updateSelectedQuestion(isApply) {
      const now = Date.now();
      if (
        this.lastAddUpdateQuestionCall &&
        now - this.lastAddUpdateQuestionCall < 1500
      ) {
        return;
      }
      this.lastAddUpdateQuestionCall = now;

      if (!this.isQuestionFieldFilled()) {
        return;
      }
      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);
      this.tags = this.getTrimmedText(this.tags);

      let self = this;

      var courseName = null;
      var subjectName = null;
      if (
        self.selectedCourseSubjectText &&
        self.selectedCourseSubjectText.length > 2
      ) {
        this.courseSubjects.forEach(function(courseSubject) {
          if (self.selectedCourseSubjectText.includes("->")) {
            if (
              self.selectedCourseSubjectText ==
              courseSubject.course_name + " -> " + courseSubject.subject_name
            ) {
              courseName = courseSubject.course_name;
              subjectName = courseSubject.subject_name;
            }
          } else if (
            self.selectedCourseSubjectText == courseSubject.course_name
          ) {
            courseName = courseSubject.course_name;
          }
        });
      }

      const [year, tusNumber] = this.yearTusNumber.split("-");
      const questionOrderNum = parseInt(this.questionOrder);

      if (
        year + "-" + tusNumber + "-" + questionOrderNum !=
        this.selectedQuestion.year +
          "-" +
          this.selectedQuestion.tus_number +
          "-" +
          this.selectedQuestion.question_order
      ) {
        var pyld = {
          year: year,
          tusNumber: tusNumber,
          questionOrder: questionOrderNum,
        };

        var available = await store.dispatch(
          "adminOsymQuestions/isQuestionAvaiable",
          pyld
        );

        if (available) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opps!",
              icon: "BellIcon",
              text: questionOrderNum + ". soru zaten mevcut!",
              variant: "danger",
            },
          });
          return;
        }
      }

      var payload = {
        year: year,
        tusNumber: tusNumber,
        questionOrder: questionOrderNum,
        courseName: courseName,
        subjectName: subjectName,
        isClinical: this.isBasicCourse(courseName) ? 0 : 1,
        questionId: this.selectedQuestion.oq_id,
        questionText: this.questionText,
        optionA: this.optionA,
        optionB: this.optionB,
        optionC: this.optionC,
        optionD: this.optionD,
        optionE: this.optionE,
        answer: this.answer,
        solution: this.solution,
        tags: this.tags,
        isApply: isApply === true,
      };

      var updated = await store.dispatch(
        "adminOsymQuestions/updateQuestion",
        payload
      );

      if (updated) {
        this.selectedAlternativeIndex = -1;
        this.showSuccess(questionOrderNum + ". soru güncellendi.");

        this.showAddQuestion();

        this.osymExams = await store.dispatch(
          "adminOsymQuestions/getOsymExams"
        );

        var currentPageIndex = this.questionsCurrentPage;

        // Find and select the exam that matches the year and tusNumber
        for (var i = 0; i < this.osymExams.length; i++) {
          const exam = this.osymExams[i];
          if (exam.year == year && exam.tus_number == tusNumber) {
            this.selectedExam = exam;
            this.$nextTick(() => {
              if (this.$refs.examTable) {
                this.$refs.examTable.selectRow(i);
              }
            });
            const payload = {
              year: this.selectedExam.year,
              tusNumber: this.selectedExam.tus_number,
            };
            var response = await store.dispatch(
              "adminOsymQuestions/getQuestionsOfExam",
              payload
            );
            if (response) {
              this.questionsOfSelectedExam = response;
            }
            // Set the current page to the previously saved page index
            this.$nextTick(() => {
              this.questionsCurrentPage = currentPageIndex;
            });
            return;
          }
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru güncellenemedi!",
            variant: "danger",
          },
        });
      }
    },

    getTrimmedText(text) {
      if (!text) {
        return "";
      }
      return text.trim();
    },

    getTagTooltipText(tagName) {
      switch (tagName) {
        case "p":
          return "Paragraf";
        case "h3":
          return "Büyük başlık";
        case "h4":
          return "Küçük başlık";
        case "b":
          return "Kalın metin";
        case "u":
          return "Altı çizili";
        case "i":
          return "İtalik";
        case "br":
          return "Yeni satır";
        case "a":
          return "Bağlantı";
        case "ul":
          return "Sırasız liste";
        case "ol":
          return "Sıralı liste";
        default:
          return "Bilinmeyen etiket";
      }
    },

    applyTag(tagName, content) {
      var firstPart = content.substring(0, this.inputSelectionStart);
      var selection = content.substring(
        this.inputSelectionStart,
        this.inputSelectionEnd
      );
      var lastPart = content.substring(this.inputSelectionEnd, content.length);

      switch (tagName) {
        case "p":
        case "h3":
        case "h4":
          if (firstPart.trim().length > 0 && !firstPart.trim().endsWith("\n")) {
            firstPart = firstPart.trim() + "\n";
          }
          selection = "<" + tagName + ">" + selection + "</" + tagName + ">\n";
          while (
            selection.includes(" </" + tagName + ">\n") ||
            selection.includes("\n</" + tagName + ">\n")
          ) {
            selection = selection.replace(
              " </" + tagName + ">\n",
              "</" + tagName + ">\n"
            );
            selection = selection.replace(
              "\n</" + tagName + ">\n",
              "</" + tagName + ">\n"
            );
          }
          selection = "\n" + selection + "\n";
          return firstPart + selection + lastPart;
        case "b":
        case "u":
        case "i":
          return (
            firstPart +
            "<" +
            tagName +
            ">" +
            selection +
            "</" +
            tagName +
            ">" +
            lastPart
          );
        case "br":
          return firstPart + "\n<br>\n" + selection + lastPart;
        case "a":
          return (
            firstPart +
            "<a href='link' target='_blank'>" +
            selection +
            "</a>" +
            lastPart
          );
        case "ul":
        case "ol":
          if (firstPart.trim().length > 0 && !firstPart.trim().endsWith("\n")) {
            firstPart = firstPart.trim() + "\n";
          }
          while (selection.includes("\n\n")) {
            selection = selection.replaceAll("\n\n", "\n");
          }
          selection = selection.replaceAll("\n", "</li>\n    <li>");
          selection =
            `<${tagName}>\n    <li>` + selection + `</li>\n</${tagName}>\n`;
          return (firstPart + selection + lastPart).replaceAll("<li></li>", "");
      }
    },

    isQuestionCompleted(question) {
      return (
        question.course_name &&
        question.course_name.trim().replace(/\n/g, "") !== "" &&
        question.subject_name &&
        question.subject_name.trim().replace(/\n/g, "") !== "" &&
        question.solution &&
        question.solution.trim().replace(/\n/g, "") !== "" &&
        question.tags &&
        question.tags.trim().replace(/\n/g, "") !== ""
      );
    },

    isQuestionFieldFilled() {
      // Check if question order is between 1 and 240
      const questionOrderNum = parseInt(this.questionOrder);
      if (
        isNaN(questionOrderNum) ||
        questionOrderNum < 1 ||
        questionOrderNum > 240
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Geçersiz Soru Sırası!",
            icon: "AlertTriangleIcon",
            text: "Soru sırası 1 ile 240 arasında olmalıdır.",
            variant: "warning",
          },
        });
        return false;
      }

      // Check if yearTusNumber is in the correct format (YYYY-N)
      if (
        this.yearTusNumber.length !== 6 ||
        this.yearTusNumber.charAt(4) !== "-"
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Geçersiz Yıl-TUS Numarası Formatı!",
            icon: "AlertTriangleIcon",
            text:
              "Yıl-TUS Numarası 'YYYY-N' formatında olmalıdır (örn. 2024-2).",
            variant: "warning",
          },
        });
        return false;
      }

      // Check if year and TUS number are valid
      const [year, tusNumber] = this.yearTusNumber.split("-");
      const currentYear = new Date().getFullYear();
      const yearNum = parseInt(year);
      const tusNum = parseInt(tusNumber);

      if (isNaN(yearNum) || yearNum < 2000 || yearNum > currentYear) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Geçersiz Yıl!",
            icon: "AlertTriangleIcon",
            text: `Yıl 2000 ile ${currentYear} arasında olmalıdır.`,
            variant: "warning",
          },
        });
        return false;
      }

      if (isNaN(tusNum) || (tusNum !== 1 && tusNum !== 2)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Geçersiz TUS Numarası!",
            icon: "AlertTriangleIcon",
            text: "TUS numarası 1 veya 2 olmalıdır.",
            variant: "warning",
          },
        });
        return false;
      }

      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);
      this.tags = this.getTrimmedText(this.tags);

      if (
        !this.questionText ||
        !this.optionA ||
        !this.optionB ||
        !this.optionC ||
        !this.optionD ||
        !this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Eksik Var!",
            icon: "BellIcon",
            text: "Lütfen soru metni ve seçenekleri doldurun!",
            variant: "danger",
          },
        });
        return false;
      }

      if (
        this.optionA == this.optionB ||
        this.optionA == this.optionC ||
        this.optionA == this.optionD ||
        this.optionA == this.optionE ||
        this.optionB == this.optionC ||
        this.optionB == this.optionD ||
        this.optionB == this.optionE ||
        this.optionC == this.optionD ||
        this.optionC == this.optionE ||
        this.optionD == this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aynı seçenek var!",
            icon: "BellIcon",
            text: "Aynı seçenekten birden fazla eklediniz!",
            variant: "danger",
          },
        });
        return false;
      }
      return true;
    },

    async addQuestion() {
      const now = Date.now();
      if (
        this.lastAddUpdateQuestionCall &&
        now - this.lastAddUpdateQuestionCall < 1500
      ) {
        return;
      }
      this.lastAddUpdateQuestionCall = now;

      if (!this.isQuestionFieldFilled()) {
        return;
      }

      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);
      this.tags = this.getTrimmedText(this.tags);

      let self = this;

      var courseName = null;
      var subjectName = null;
      if (
        self.selectedCourseSubjectText &&
        self.selectedCourseSubjectText.length > 2
      ) {
        this.courseSubjects.forEach(function(courseSubject) {
          if (self.selectedCourseSubjectText.includes("->")) {
            if (
              self.selectedCourseSubjectText ==
              courseSubject.course_name + " -> " + courseSubject.subject_name
            ) {
              courseName = courseSubject.course_name;
              subjectName = courseSubject.subject_name;
            }
          } else if (
            self.selectedCourseSubjectText == courseSubject.course_name
          ) {
            courseName = courseSubject.course_name;
          }
        });
      }

      const [year, tusNumber] = this.yearTusNumber.split("-");
      const questionOrderNum = parseInt(this.questionOrder);

      var pyld = {
        year: year,
        tusNumber: tusNumber,
        questionOrder: questionOrderNum,
      };

      var available = await store.dispatch(
        "adminOsymQuestions/isQuestionAvaiable",
        pyld
      );

      if (available) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opps!",
            icon: "BellIcon",
            text: questionOrderNum + ". soru zaten mevcut!",
            variant: "danger",
          },
        });
        return;
      }

      var payload = {
        year: year,
        tusNumber: tusNumber,
        questionOrder: questionOrderNum,
        courseName: courseName,
        subjectName: subjectName,
        isClinical: this.isBasicCourse(courseName) ? 0 : 1,
        questionText: this.questionText,
        optionA: this.optionA,
        optionB: this.optionB,
        optionC: this.optionC,
        optionD: this.optionD,
        optionE: this.optionE,
        answer: this.answer,
        solution: this.solution,
        tags: this.tags,
      };
      var added = await store.dispatch(
        "adminOsymQuestions/addQuestion",
        payload
      );

      if (added) {
        this.showSuccess("Yazdığınız soru veritabanına eklendi.");

        this.questionOrder = "" + (questionOrderNum + 1);

        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.tags = "";
        this.uploadedImageUrls = [];

        this.osymExams = await store.dispatch(
          "adminOsymQuestions/getOsymExams"
        );

        var currentPageIndex = this.questionsCurrentPage;

        // Find and select the exam that matches the year and tusNumber
        for (var i = 0; i < this.osymExams.length; i++) {
          const exam = this.osymExams[i];
          if (exam.year == year && exam.tus_number == tusNumber) {
            this.selectedExam = exam;
            this.$nextTick(() => {
              if (this.$refs.examTable) {
                this.$refs.examTable.selectRow(i);
              }
            });
            const payload = {
              year: this.selectedExam.year,
              tusNumber: this.selectedExam.tus_number,
            };
            var response = await store.dispatch(
              "adminOsymQuestions/getQuestionsOfExam",
              payload
            );
            if (response) {
              this.questionsOfSelectedExam = response;
            }
            // Set the current page to the previously saved page index
            this.$nextTick(() => {
              this.questionsCurrentPage = currentPageIndex;
            });
            return;
          }
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru eklenemedi!",
            variant: "danger",
          },
        });
      }
    },

    async deleteSelectedQuestion() {
      this.$refs["modal-delete-question"].hide();
      var deleted = store.dispatch(
        "adminOsymQuestions/deleteQuestion",
        this.selectedQuestion.oq_id
      );
      if (deleted) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Seçili soru silindi.",
            variant: "success",
          },
        });

        var year = this.selectedExam.year;
        var tusNumber = this.selectedExam.tus_number;

        this.osymExams = await store.dispatch(
          "adminOsymQuestions/getOsymExams"
        );

        // Find and select the exam that matches the year and tusNumber
        for (var i = 0; i < this.osymExams.length; i++) {
          const exam = this.osymExams[i];
          if (exam.year == year && exam.tus_number == tusNumber) {
            this.selectedExam = exam;
            this.$nextTick(() => {
              if (this.$refs.examTable) {
                this.$refs.examTable.selectRow(i);
              }
            });
            const payload = {
              year: this.selectedExam.year,
              tusNumber: this.selectedExam.tus_number,
            };
            var questions = await store.dispatch(
              "adminOsymQuestions/getQuestionsOfExam",
              payload
            );
            if (questions) {
              this.questionsOfSelectedExam = questions;
            }
          }
        }

        this.showAddQuestion();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru silinemedi!",
            variant: "danger",
          },
        });
      }
    },

    uploadNewImage() {
      var file = this.newImage;
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Seçilen dosya boyutu en fazla 5 MB olmalıdır!",
              variant: "danger",
            },
          });
          return;
        }
        if (
          !(
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png"
          )
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hatalı format!",
              icon: "BellIcon",
              text: "Kabul edilen dosya formatı: JPG, JPEG, PNG",
              variant: "danger",
            },
          });
          return;
        }

        let self = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(event) {
          var imageBase64 = reader.result;
          store
            .dispatch("adminOsymQuestions/uploadImage", imageBase64)
            .then((response) => {
              if (response) {
                self.newImage = null;
                self.uploadedImageUrls.push(response);
                self.$bvToast.toast("", {
                  title: `Fotograf Yüklendi`,
                  variant: `success`,
                  solid: true,
                });
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Hata oluştu!",
                    icon: "BellIcon",
                    text:
                      "Oluşan bir sorundan dolayı yükleme işlemi gerçekleştirilemedi.",
                    variant: "danger",
                  },
                });
              }
            });
        };
        reader.onerror = function(error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hay Aksi!",
              icon: "BellIcon",
              text: "Bilinmeyen bir hata oluştu",
              variant: "danger",
            },
          });
        };
      }
    },

    yearTusNumberFormatter: function(value) {
      // Remove all non-digit characters except for the hyphen
      const cleanedValue = value.replace(/[^\d-]/g, "");

      var formattedCode = "";
      // En fazla 5 rakam ve 1 tire olacak şekilde döngü kur
      for (var i = 0; i < cleanedValue.length && i < 6; i++) {
        var character = cleanedValue[i];

        // Yalnızca rakam olduğunda işlem yap
        if (this.isInt(character)) {
          if (formattedCode.length === 4) {
            // Üç rakamdan sonra tire ekle
            formattedCode += "-";
          }
          // Rakamı ekle
          formattedCode += character;
        }
      }
      this.yearTusNumber = formattedCode;
      return this.yearTusNumber;
    },

    questionOrderFormatter: function(value) {
      // Remove all non-digit characters except for the hyphen
      const cleanedValue = value.replace(/[^\d-]/g, "");
      var formattedCode = "";
      // En fazla 5 rakam ve 1 tire olacak şekilde döngü kur
      for (var i = 0; i < cleanedValue.length && i < 3; i++) {
        var character = cleanedValue[i];
        // Yalnızca rakam olduğunda işlem yap
        if (this.isInt(character)) {
          // Rakamı ekle
          formattedCode += character;
        }
      }
      this.questionOrder = formattedCode;
      return this.questionOrder;
    },

    handleKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "e") {
        event.preventDefault(); // Prevent default browser behavior
        if (!this.selectedQuestion) {
          this.addQuestion();
        }
      }
    },
  },

  async created() {
    let moduleId = "osym_questions";
    let moduleName = "ÖSYM Soruları";
    if (JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1) {
      this.$router.push("/erisim-kapali?moduleName=" + moduleName);
      return;
    }

    var self = this;
    setInterval(function() {
      var el = document.activeElement;
      if (
        el &&
        ((el.tagName.toLowerCase() == "input" && el.type == "text") ||
          el.tagName.toLowerCase() == "textarea")
      ) {
        var elementId = el.id;
        if (elementId == "question-text" || elementId == "solution-text") {
          var element = document.getElementById(elementId);
          if (element) {
            self.inputSelectionStart = element.selectionStart;
            self.inputSelectionEnd = element.selectionEnd;
          }
        }
      }
    }, 500);

    this.osymExams = await store.dispatch("adminOsymQuestions/getOsymExams");

    this.courseSubjects = await store.dispatch(
      "adminOsymQuestions/getCourseSubjects"
    );

    this.loading = false;

    const checkKey = (e) => {
      e = e || window.event;
      if (
        e.shiftKey &&
        (e.key === "ArrowLeft" || e.key === "ArrowUp") &&
        this.questionsCurrentPage > 1
      ) {
        this.questionsCurrentPage--;
      } else if (
        e.shiftKey &&
        (e.key === "ArrowRight" || e.key === "ArrowDown") &&
        this.questionsCurrentPage <
          Math.ceil(
            this.questionsInOrderOfSelectedExam.length / this.questionsPerPage
          )
      ) {
        this.questionsCurrentPage++;
      }
    };

    window.addEventListener("keydown", checkKey);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", checkKey);
    });

    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key === "s") {
          e.preventDefault();
          if (this.selectedQuestion) {
            this.updateSelectedQuestion();
          } else {
            this.addQuestion();
          }
        }
      },
      false
    );
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.max-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.form-control {
  padding: 0.438rem 0.7rem;
}
</style>
